<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<body>
    <div class="container mt-4">
        <div class="card">
          <div class="card-header">
            <h3 style="font-weight: bold;">Branches accessible</h3>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-sm-6 col-md-4 mx-auto" *ngFor="let branch of branches">
                <div class="card mb-4">
                  <div class="card-body">
                    <h5 class="card-title"><span style="font-weight: bold;">Branch Name: </span>{{ branch.branch.branchName }}</h5>
                    <p class="card-text"><span style="font-weight: bold;">Role: </span>{{loginData.branches.role}}</p>
                    <div class="row">
                      <div class="col-auto mx-auto">
                        <button (click)="showBranchDetails(branch.branch.id)" class="btn btn-primary">View Details</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
</body>
</html>