<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Document</title>
  </head>
  <body>
    <div class="d-flex justify-content-center" *ngIf="!isLoaded">
      <div class="spinner-grow text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div class="container-fluid" [hidden]="!isLoaded">
      <nav class="navbar navbar-expand-lg navbar-light">
        <a class="navbar-brand" href="#">
          <img *ngIf="!isOsyncs"
            src="../../../assets/img/ColouredLogoAplifast.svg"
            alt="Logo"
            class="logo"
          />
          <img *ngIf="isOsyncs"
            src="../../../assets/img/Osyncs logo.png"
            alt="Logo"
            class="logo"
          />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mr-auto ml-auto">
            <li class="nav-item active">
              <a class="nav-link" href="#"
                >Home <span class="sr-only">(current)</span></a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">About us</a>
            </li>
          </ul>
          <ul class="navbar-nav marginNavCenter">
            <li class="nav-item active">
              <button
                data-toggle="modal"
                data-target="#exampleModalCenter"
                class="btn btn-primary my-3 my-sm-0 startLoginButton"
                type="submit"
              >
                <i class="far fa-hand-pointer prefix grey-text"></i> START/
                LOGIN
              </button>
            </li>
          </ul>
        </div>
      </nav>
      <div class="row">
        <div class="col-md-8 col-sm-12 mx-auto alignCenter">
          <h1 class="headerH">Smoothen Your Immigration Business With Our CRM</h1>
        </div>
      </div>
        <div class="row">

          <div class="col-md-7 col-sm-12 mx-auto alignCenter">
            <p>
              Manage & track student's applications at each step of the admission
              process with 0ne click. Save time counselling while converting more
            students with our smart CRM.
          </p>
        </div>
      </div>
      <div class="row firstRow">
        <div class="col-12 mx-auto px-auto">
          <svg
            viewBox="0 0 1590 529"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="Group 23" style="mix-blend-mode: multiply">
              <g id="Wheel">
                <g id="Group">
                  <path
                    id="Vector"
                    d="M560.715 218.566V479.253"
                    stroke="#294456"
                    style="
                      stroke: #294456;
                      stroke: color(display-p3 0.1608 0.2667 0.3373);
                      stroke-opacity: 1;
                    "
                    stroke-width="2.654"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    id="Vector_2"
                    d="M513.249 227.516L608.18 470.303"
                    stroke="#294456"
                    style="
                      stroke: #294456;
                      stroke: color(display-p3 0.1608 0.2667 0.3373);
                      stroke-opacity: 1;
                    "
                    stroke-width="2.654"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    id="Vector_3"
                    d="M430.33 348.91H691.099"
                    stroke="#294456"
                    style="
                      stroke: #294456;
                      stroke: color(display-p3 0.1608 0.2667 0.3373);
                      stroke-opacity: 1;
                    "
                    stroke-width="2.654"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    id="Vector_4"
                    d="M468.523 441.072L652.907 256.747"
                    stroke="#294456"
                    style="
                      stroke: #294456;
                      stroke: color(display-p3 0.1608 0.2667 0.3373);
                      stroke-opacity: 1;
                    "
                    stroke-width="2.654"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    id="Vector_5"
                    d="M512.581 470.049L608.839 227.77"
                    stroke="#294456"
                    style="
                      stroke: #294456;
                      stroke: color(display-p3 0.1608 0.2667 0.3373);
                      stroke-opacity: 1;
                    "
                    stroke-width="2.654"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    id="Vector_6"
                    d="M439.368 396.595L682.052 301.224"
                    stroke="#294456"
                    style="
                      stroke: #294456;
                      stroke: color(display-p3 0.1608 0.2667 0.3373);
                      stroke-opacity: 1;
                    "
                    stroke-width="2.654"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    id="Vector_7"
                    d="M468.523 256.747L652.907 441.072"
                    stroke="#294456"
                    style="
                      stroke: #294456;
                      stroke: color(display-p3 0.1608 0.2667 0.3373);
                      stroke-opacity: 1;
                    "
                    stroke-width="2.654"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    id="Vector_8"
                    d="M439.217 301.628L682.212 396.2"
                    stroke="#294456"
                    style="
                      stroke: #294456;
                      stroke: color(display-p3 0.1608 0.2667 0.3373);
                      stroke-opacity: 1;
                    "
                    stroke-width="2.654"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    id="Vector_9"
                    d="M430.33 348.91C430.33 420.895 488.707 479.253 560.715 479.253C632.723 479.253 691.099 420.885 691.099 348.91C691.099 276.924 632.723 218.566 560.715 218.566C488.707 218.566 430.33 276.924 430.33 348.91Z"
                    stroke="#294456"
                    style="
                      stroke: #294456;
                      stroke: color(display-p3 0.1608 0.2667 0.3373);
                      stroke-opacity: 1;
                    "
                    stroke-width="2.654"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    id="Vector_10"
                    d="M446.927 348.909C446.927 411.728 497.867 462.652 560.705 462.652C623.544 462.652 674.484 411.728 674.484 348.909C674.484 286.091 623.544 235.167 560.705 235.167C497.876 235.167 446.927 286.091 446.927 348.909Z"
                    stroke="#294456"
                    style="
                      stroke: #294456;
                      stroke: color(display-p3 0.1608 0.2667 0.3373);
                      stroke-opacity: 1;
                    "
                    stroke-width="2.654"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    id="Vector_11"
                    d="M452.237 348.91C452.237 408.802 500.804 457.353 560.715 457.353C620.626 457.353 669.183 408.802 669.183 348.91C669.183 289.027 620.616 240.466 560.715 240.466C500.804 240.466 452.237 289.027 452.237 348.91Z"
                    stroke="#294456"
                    style="
                      stroke: #294456;
                      stroke: color(display-p3 0.1608 0.2667 0.3373);
                      stroke-opacity: 1;
                    "
                    stroke-width="2.654"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    id="Vector_12"
                    d="M551.178 348.91C551.178 354.17 555.443 358.434 560.705 358.434C565.968 358.434 570.242 354.17 570.242 348.91C570.242 343.649 565.968 339.385 560.705 339.385C555.443 339.385 551.178 343.658 551.178 348.91Z"
                    fill="#F8FCFF"
                    stroke="#294456"
                    style="
                      fill: #f8fcff;
                      fill: color(display-p3 0.9725 0.9882 1);
                      fill-opacity: 1;
                      stroke: #294456;
                      stroke: color(display-p3 0.1608 0.2667 0.3373);
                      stroke-opacity: 1;
                    "
                    stroke-width="2.654"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    id="Vector_13"
                    d="M557.307 348.909C557.307 350.792 558.832 352.316 560.715 352.316C562.598 352.316 564.123 350.792 564.123 348.909C564.123 347.027 562.598 345.503 560.715 345.503C558.832 345.503 557.307 347.037 557.307 348.909Z"
                    fill="#294456"
                    style="
                      fill: #294456;
                      fill: color(display-p3 0.1608 0.2667 0.3373);
                      fill-opacity: 1;
                    "
                  />
                </g>
                <g id="Group_2">
                  <path
                    id="Vector_14"
                    d="M608.604 236.203C615.358 236.203 620.833 231.88 620.833 226.547C620.833 221.214 615.358 216.891 608.604 216.891C601.85 216.891 596.375 221.214 596.375 226.547C596.375 231.88 601.85 236.203 608.604 236.203Z"
                    fill="#F8FCFF"
                    stroke="#294456"
                    style="
                      fill: #f8fcff;
                      fill: color(display-p3 0.9725 0.9882 1);
                      fill-opacity: 1;
                      stroke: #294456;
                      stroke: color(display-p3 0.1608 0.2667 0.3373);
                      stroke-opacity: 1;
                    "
                    stroke-width="1.5924"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <g id="Group_3">
                    <path
                      id="Vector_15"
                      d="M608.679 221.898H613.066C615.053 221.898 616.041 222.782 616.041 224.458V228.015H608.679V221.898Z"
                      fill="#8FB4BA"
                      stroke="#294456"
                      style="
                        fill: #8fb4ba;
                        fill: color(display-p3 0.5608 0.7059 0.7294);
                        fill-opacity: 1;
                        stroke: #294456;
                        stroke: color(display-p3 0.1608 0.2667 0.3373);
                        stroke-opacity: 1;
                      "
                      stroke-width="1.5924"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      id="Vector_16"
                      d="M604.104 221.898H608.679V228.062L601.158 228.015V224.458C601.158 222.782 602.118 221.898 604.104 221.898Z"
                      fill="#8FB4BA"
                      stroke="#294456"
                      style="
                        fill: #8fb4ba;
                        fill: color(display-p3 0.5608 0.7059 0.7294);
                        fill-opacity: 1;
                        stroke: #294456;
                        stroke: color(display-p3 0.1608 0.2667 0.3373);
                        stroke-opacity: 1;
                      "
                      stroke-width="1.5924"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                </g>
                <g id="Group_4">
                  <path
                    id="Vector_17"
                    d="M513.014 236.203C519.768 236.203 525.243 231.88 525.243 226.547C525.243 221.214 519.768 216.891 513.014 216.891C506.26 216.891 500.785 221.214 500.785 226.547C500.785 231.88 506.26 236.203 513.014 236.203Z"
                    fill="#F8FCFF"
                    stroke="#294456"
                    style="
                      fill: #f8fcff;
                      fill: color(display-p3 0.9725 0.9882 1);
                      fill-opacity: 1;
                      stroke: #294456;
                      stroke: color(display-p3 0.1608 0.2667 0.3373);
                      stroke-opacity: 1;
                    "
                    stroke-width="1.5924"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <g id="Group_5">
                    <path
                      id="Vector_18"
                      d="M513.089 221.898H517.476C519.462 221.898 520.451 222.782 520.451 224.458V228.015H513.089V221.898Z"
                      fill="#8FB4BA"
                      stroke="#294456"
                      style="
                        fill: #8fb4ba;
                        fill: color(display-p3 0.5608 0.7059 0.7294);
                        fill-opacity: 1;
                        stroke: #294456;
                        stroke: color(display-p3 0.1608 0.2667 0.3373);
                        stroke-opacity: 1;
                      "
                      stroke-width="1.5924"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      id="Vector_19"
                      d="M508.504 221.898H513.089V228.062L505.567 228.015V224.458C505.567 222.782 506.528 221.898 508.504 221.898Z"
                      fill="#8FB4BA"
                      stroke="#294456"
                      style="
                        fill: #8fb4ba;
                        fill: color(display-p3 0.5608 0.7059 0.7294);
                        fill-opacity: 1;
                        stroke: #294456;
                        stroke: color(display-p3 0.1608 0.2667 0.3373);
                        stroke-opacity: 1;
                      "
                      stroke-width="1.5924"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                </g>
                <g id="Group_6">
                  <path
                    id="Vector_20"
                    d="M620.824 470.557C620.824 475.884 615.354 480.203 608.595 480.203C601.845 480.203 596.366 475.884 596.366 470.557C596.366 465.23 601.835 460.901 608.595 460.901C615.354 460.901 620.824 465.23 620.824 470.557Z"
                    fill="#F8FCFF"
                    stroke="#294456"
                    style="
                      fill: #f8fcff;
                      fill: color(display-p3 0.9725 0.9882 1);
                      fill-opacity: 1;
                      stroke: #294456;
                      stroke: color(display-p3 0.1608 0.2667 0.3373);
                      stroke-opacity: 1;
                    "
                    stroke-width="1.5924"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <g id="Group_7">
                    <path
                      id="Vector_21"
                      d="M608.679 465.917H613.066C615.053 465.917 616.041 466.802 616.041 468.477V472.035H608.679V465.917Z"
                      fill="#8FB4BA"
                      stroke="#294456"
                      style="
                        fill: #8fb4ba;
                        fill: color(display-p3 0.5608 0.7059 0.7294);
                        fill-opacity: 1;
                        stroke: #294456;
                        stroke: color(display-p3 0.1608 0.2667 0.3373);
                        stroke-opacity: 1;
                      "
                      stroke-width="1.5924"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      id="Vector_22"
                      d="M604.104 465.917H608.679V472.082L601.158 472.035V468.477C601.158 466.802 602.118 465.917 604.104 465.917Z"
                      fill="#8FB4BA"
                      stroke="#294456"
                      style="
                        fill: #8fb4ba;
                        fill: color(display-p3 0.5608 0.7059 0.7294);
                        fill-opacity: 1;
                        stroke: #294456;
                        stroke: color(display-p3 0.1608 0.2667 0.3373);
                        stroke-opacity: 1;
                      "
                      stroke-width="1.5924"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                </g>
                <g id="Group_8">
                  <path
                    id="Vector_23"
                    d="M525.233 470.557C525.233 475.884 519.754 480.203 513.004 480.203C506.255 480.203 500.776 475.884 500.776 470.557C500.776 465.23 506.245 460.901 513.004 460.901C519.764 460.901 525.233 465.23 525.233 470.557Z"
                    fill="#F8FCFF"
                    stroke="#294456"
                    style="
                      fill: #f8fcff;
                      fill: color(display-p3 0.9725 0.9882 1);
                      fill-opacity: 1;
                      stroke: #294456;
                      stroke: color(display-p3 0.1608 0.2667 0.3373);
                      stroke-opacity: 1;
                    "
                    stroke-width="1.5924"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <g id="Group_9">
                    <path
                      id="Vector_24"
                      d="M513.089 465.917H517.476C519.462 465.917 520.451 466.802 520.451 468.477V472.035H513.089V465.917Z"
                      fill="#8FB4BA"
                      stroke="#294456"
                      style="
                        fill: #8fb4ba;
                        fill: color(display-p3 0.5608 0.7059 0.7294);
                        fill-opacity: 1;
                        stroke: #294456;
                        stroke: color(display-p3 0.1608 0.2667 0.3373);
                        stroke-opacity: 1;
                      "
                      stroke-width="1.5924"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      id="Vector_25"
                      d="M508.504 465.917H513.089V472.082L505.567 472.035V468.477C505.567 466.802 506.528 465.917 508.504 465.917Z"
                      fill="#8FB4BA"
                      stroke="#294456"
                      style="
                        fill: #8fb4ba;
                        fill: color(display-p3 0.5608 0.7059 0.7294);
                        fill-opacity: 1;
                        stroke: #294456;
                        stroke: color(display-p3 0.1608 0.2667 0.3373);
                        stroke-opacity: 1;
                      "
                      stroke-width="1.5924"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                </g>
                <g id="Group_10">
                  <path
                    id="Vector_26"
                    d="M572.614 216.656C572.614 221.982 567.145 226.312 560.385 226.312C553.635 226.312 548.156 221.992 548.156 216.656C548.156 211.329 553.626 207 560.385 207C567.145 207 572.614 211.329 572.614 216.656Z"
                    fill="#F8FCFF"
                    stroke="#294456"
                    style="
                      fill: #f8fcff;
                      fill: color(display-p3 0.9725 0.9882 1);
                      fill-opacity: 1;
                      stroke: #294456;
                      stroke: color(display-p3 0.1608 0.2667 0.3373);
                      stroke-opacity: 1;
                    "
                    stroke-width="1.5924"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <g id="Group_11">
                    <path
                      id="Vector_27"
                      d="M560.47 212.016H564.857C566.843 212.016 567.832 212.901 567.832 214.567V218.124H560.47V212.016Z"
                      fill="#8FB4BA"
                      stroke="#294456"
                      style="
                        fill: #8fb4ba;
                        fill: color(display-p3 0.5608 0.7059 0.7294);
                        fill-opacity: 1;
                        stroke: #294456;
                        stroke: color(display-p3 0.1608 0.2667 0.3373);
                        stroke-opacity: 1;
                      "
                      stroke-width="1.5924"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      id="Vector_28"
                      d="M555.885 212.016H560.461V218.18L552.939 218.133V214.576C552.948 212.901 553.908 212.016 555.885 212.016Z"
                      fill="#8FB4BA"
                      stroke="#294456"
                      style="
                        fill: #8fb4ba;
                        fill: color(display-p3 0.5608 0.7059 0.7294);
                        fill-opacity: 1;
                        stroke: #294456;
                        stroke: color(display-p3 0.1608 0.2667 0.3373);
                        stroke-opacity: 1;
                      "
                      stroke-width="1.5924"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                </g>
                <g id="Group_12">
                  <path
                    id="Vector_29"
                    d="M572.614 479.893C572.614 485.22 567.145 489.549 560.385 489.549C553.635 489.549 548.156 485.229 548.156 479.893C548.156 474.566 553.626 470.247 560.385 470.247C567.145 470.247 572.614 474.566 572.614 479.893Z"
                    fill="#F8FCFF"
                    stroke="#294456"
                    style="
                      fill: #f8fcff;
                      fill: color(display-p3 0.9725 0.9882 1);
                      fill-opacity: 1;
                      stroke: #294456;
                      stroke: color(display-p3 0.1608 0.2667 0.3373);
                      stroke-opacity: 1;
                    "
                    stroke-width="1.5924"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <g id="Group_13">
                    <path
                      id="Vector_30"
                      d="M560.47 475.253H564.857C566.843 475.253 567.832 476.138 567.832 477.813V481.37H560.47V475.253Z"
                      fill="#8FB4BA"
                      stroke="#294456"
                      style="
                        fill: #8fb4ba;
                        fill: color(display-p3 0.5608 0.7059 0.7294);
                        fill-opacity: 1;
                        stroke: #294456;
                        stroke: color(display-p3 0.1608 0.2667 0.3373);
                        stroke-opacity: 1;
                      "
                      stroke-width="1.5924"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      id="Vector_31"
                      d="M555.885 475.253H560.461V481.417L552.939 481.37V477.813C552.948 476.138 553.908 475.253 555.885 475.253Z"
                      fill="#8FB4BA"
                      stroke="#294456"
                      style="
                        fill: #8fb4ba;
                        fill: color(display-p3 0.5608 0.7059 0.7294);
                        fill-opacity: 1;
                        stroke: #294456;
                        stroke: color(display-p3 0.1608 0.2667 0.3373);
                        stroke-opacity: 1;
                      "
                      stroke-width="1.5924"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                </g>
                <g id="Group_14">
                  <path
                    id="Vector_32"
                    d="M666.425 254.055C666.425 259.382 660.956 263.711 654.196 263.711C647.446 263.711 641.967 259.391 641.967 254.055C641.967 248.729 647.446 244.409 654.196 244.409C660.956 244.4 666.425 248.719 666.425 254.055Z"
                    fill="#F8FCFF"
                    stroke="#294456"
                    style="
                      fill: #f8fcff;
                      fill: color(display-p3 0.9725 0.9882 1);
                      fill-opacity: 1;
                      stroke: #294456;
                      stroke: color(display-p3 0.1608 0.2667 0.3373);
                      stroke-opacity: 1;
                    "
                    stroke-width="1.5924"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <g id="Group_15">
                    <path
                      id="Vector_33"
                      d="M654.281 249.416H658.668C660.654 249.416 661.643 250.3 661.643 251.975V255.533H654.281V249.416Z"
                      fill="#8FB4BA"
                      stroke="#294456"
                      style="
                        fill: #8fb4ba;
                        fill: color(display-p3 0.5608 0.7059 0.7294);
                        fill-opacity: 1;
                        stroke: #294456;
                        stroke: color(display-p3 0.1608 0.2667 0.3373);
                        stroke-opacity: 1;
                      "
                      stroke-width="1.5924"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      id="Vector_34"
                      d="M649.696 249.416H654.272V255.58L646.75 255.533V251.975C646.759 250.291 647.719 249.416 649.696 249.416Z"
                      fill="#8FB4BA"
                      stroke="#294456"
                      style="
                        fill: #8fb4ba;
                        fill: color(display-p3 0.5608 0.7059 0.7294);
                        fill-opacity: 1;
                        stroke: #294456;
                        stroke: color(display-p3 0.1608 0.2667 0.3373);
                        stroke-opacity: 1;
                      "
                      stroke-width="1.5924"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                </g>
                <g id="Group_16">
                  <path
                    id="Vector_35"
                    d="M480.215 254.055C480.215 259.382 474.746 263.711 467.986 263.711C461.227 263.711 455.758 259.391 455.758 254.055C455.758 248.729 461.237 244.409 467.986 244.409C474.736 244.409 480.215 248.719 480.215 254.055Z"
                    fill="#F8FCFF"
                    stroke="#294456"
                    style="
                      fill: #f8fcff;
                      fill: color(display-p3 0.9725 0.9882 1);
                      fill-opacity: 1;
                      stroke: #294456;
                      stroke: color(display-p3 0.1608 0.2667 0.3373);
                      stroke-opacity: 1;
                    "
                    stroke-width="1.5924"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <g id="Group_17">
                    <path
                      id="Vector_36"
                      d="M468.062 249.416H472.449C474.435 249.416 475.424 250.3 475.424 251.975V255.533H468.062V249.416Z"
                      fill="#8FB4BA"
                      stroke="#294456"
                      style="
                        fill: #8fb4ba;
                        fill: color(display-p3 0.5608 0.7059 0.7294);
                        fill-opacity: 1;
                        stroke: #294456;
                        stroke: color(display-p3 0.1608 0.2667 0.3373);
                        stroke-opacity: 1;
                      "
                      stroke-width="1.5924"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      id="Vector_37"
                      d="M463.487 249.416H468.062V255.58L460.54 255.533V251.975C460.54 250.291 461.51 249.416 463.487 249.416Z"
                      fill="#8FB4BA"
                      stroke="#294456"
                      style="
                        fill: #8fb4ba;
                        fill: color(display-p3 0.5608 0.7059 0.7294);
                        fill-opacity: 1;
                        stroke: #294456;
                        stroke: color(display-p3 0.1608 0.2667 0.3373);
                        stroke-opacity: 1;
                      "
                      stroke-width="1.5924"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                </g>
                <g id="Group_18">
                  <path
                    id="Vector_38"
                    d="M666.425 440.724C666.425 446.051 660.956 450.38 654.196 450.38C647.446 450.38 641.967 446.06 641.967 440.724C641.967 435.397 647.446 431.078 654.196 431.078C660.956 431.078 666.425 435.397 666.425 440.724Z"
                    fill="#F8FCFF"
                    stroke="#294456"
                    style="
                      fill: #f8fcff;
                      fill: color(display-p3 0.9725 0.9882 1);
                      fill-opacity: 1;
                      stroke: #294456;
                      stroke: color(display-p3 0.1608 0.2667 0.3373);
                      stroke-opacity: 1;
                    "
                    stroke-width="1.5924"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <g id="Group_19">
                    <path
                      id="Vector_39"
                      d="M654.281 436.084H658.668C660.654 436.084 661.643 436.969 661.643 438.644V442.201H654.281V436.084Z"
                      fill="#8FB4BA"
                      stroke="#294456"
                      style="
                        fill: #8fb4ba;
                        fill: color(display-p3 0.5608 0.7059 0.7294);
                        fill-opacity: 1;
                        stroke: #294456;
                        stroke: color(display-p3 0.1608 0.2667 0.3373);
                        stroke-opacity: 1;
                      "
                      stroke-width="1.5924"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      id="Vector_40"
                      d="M649.696 436.084H654.272V442.249L646.75 442.201V438.644C646.759 436.969 647.719 436.084 649.696 436.084Z"
                      fill="#8FB4BA"
                      stroke="#294456"
                      style="
                        fill: #8fb4ba;
                        fill: color(display-p3 0.5608 0.7059 0.7294);
                        fill-opacity: 1;
                        stroke: #294456;
                        stroke: color(display-p3 0.1608 0.2667 0.3373);
                        stroke-opacity: 1;
                      "
                      stroke-width="1.5924"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                </g>
                <g id="Group_20">
                  <path
                    id="Vector_41"
                    d="M480.215 440.724C480.215 446.051 474.746 450.38 467.986 450.38C461.227 450.38 455.758 446.06 455.758 440.724C455.758 435.397 461.237 431.078 467.986 431.078C474.736 431.078 480.215 435.397 480.215 440.724Z"
                    fill="#F8FCFF"
                    stroke="#294456"
                    style="
                      fill: #f8fcff;
                      fill: color(display-p3 0.9725 0.9882 1);
                      fill-opacity: 1;
                      stroke: #294456;
                      stroke: color(display-p3 0.1608 0.2667 0.3373);
                      stroke-opacity: 1;
                    "
                    stroke-width="1.5924"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <g id="Group_21">
                    <path
                      id="Vector_42"
                      d="M468.062 436.084H472.449C474.435 436.084 475.424 436.969 475.424 438.644V442.201H468.062V436.084Z"
                      fill="#8FB4BA"
                      stroke="#294456"
                      style="
                        fill: #8fb4ba;
                        fill: color(display-p3 0.5608 0.7059 0.7294);
                        fill-opacity: 1;
                        stroke: #294456;
                        stroke: color(display-p3 0.1608 0.2667 0.3373);
                        stroke-opacity: 1;
                      "
                      stroke-width="1.5924"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      id="Vector_43"
                      d="M463.487 436.084H468.062V442.249L460.54 442.201V438.644C460.54 436.969 461.51 436.084 463.487 436.084Z"
                      fill="#8FB4BA"
                      stroke="#294456"
                      style="
                        fill: #8fb4ba;
                        fill: color(display-p3 0.5608 0.7059 0.7294);
                        fill-opacity: 1;
                        stroke: #294456;
                        stroke: color(display-p3 0.1608 0.2667 0.3373);
                        stroke-opacity: 1;
                      "
                      stroke-width="1.5924"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                </g>
                <g id="Group_22">
                  <path
                    id="Vector_44"
                    d="M694.884 299.831C694.884 305.157 689.405 309.477 682.655 309.477C675.905 309.477 670.426 305.157 670.426 299.831C670.426 294.504 675.905 290.175 682.655 290.175C689.405 290.175 694.884 294.504 694.884 299.831Z"
                    fill="#F8FCFF"
                    stroke="#294456"
                    style="
                      fill: #f8fcff;
                      fill: color(display-p3 0.9725 0.9882 1);
                      fill-opacity: 1;
                      stroke: #294456;
                      stroke: color(display-p3 0.1608 0.2667 0.3373);
                      stroke-opacity: 1;
                    "
                    stroke-width="1.5924"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <g id="Group_23">
                    <path
                      id="Vector_45"
                      d="M682.73 295.191H687.117C689.104 295.191 690.092 296.076 690.092 297.751V301.308H682.73V295.191Z"
                      fill="#8FB4BA"
                      stroke="#294456"
                      style="
                        fill: #8fb4ba;
                        fill: color(display-p3 0.5608 0.7059 0.7294);
                        fill-opacity: 1;
                        stroke: #294456;
                        stroke: color(display-p3 0.1608 0.2667 0.3373);
                        stroke-opacity: 1;
                      "
                      stroke-width="1.5924"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      id="Vector_46"
                      d="M678.155 295.191H682.74V301.355L675.218 301.308V297.751C675.218 296.076 676.178 295.191 678.155 295.191Z"
                      fill="#8FB4BA"
                      stroke="#294456"
                      style="
                        fill: #8fb4ba;
                        fill: color(display-p3 0.5608 0.7059 0.7294);
                        fill-opacity: 1;
                        stroke: #294456;
                        stroke: color(display-p3 0.1608 0.2667 0.3373);
                        stroke-opacity: 1;
                      "
                      stroke-width="1.5924"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                </g>
                <g id="Group_24">
                  <path
                    id="Vector_47"
                    d="M450.345 299.831C450.345 305.157 444.875 309.477 438.116 309.477C431.366 309.477 425.887 305.157 425.887 299.831C425.887 294.504 431.366 290.175 438.116 290.175C444.875 290.184 450.345 294.504 450.345 299.831Z"
                    fill="#F8FCFF"
                    stroke="#294456"
                    style="
                      fill: #f8fcff;
                      fill: color(display-p3 0.9725 0.9882 1);
                      fill-opacity: 1;
                      stroke: #294456;
                      stroke: color(display-p3 0.1608 0.2667 0.3373);
                      stroke-opacity: 1;
                    "
                    stroke-width="1.5924"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <g id="Group_25">
                    <path
                      id="Vector_48"
                      d="M438.2 295.191H442.587C444.574 295.191 445.562 296.076 445.562 297.751V301.308H438.2V295.191Z"
                      fill="#8FB4BA"
                      stroke="#294456"
                      style="
                        fill: #8fb4ba;
                        fill: color(display-p3 0.5608 0.7059 0.7294);
                        fill-opacity: 1;
                        stroke: #294456;
                        stroke: color(display-p3 0.1608 0.2667 0.3373);
                        stroke-opacity: 1;
                      "
                      stroke-width="1.5924"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      id="Vector_49"
                      d="M433.616 295.191H438.2V301.355L430.679 301.308V297.751C430.679 296.076 431.639 295.191 433.616 295.191Z"
                      fill="#8FB4BA"
                      stroke="#294456"
                      style="
                        fill: #8fb4ba;
                        fill: color(display-p3 0.5608 0.7059 0.7294);
                        fill-opacity: 1;
                        stroke: #294456;
                        stroke: color(display-p3 0.1608 0.2667 0.3373);
                        stroke-opacity: 1;
                      "
                      stroke-width="1.5924"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                </g>
                <g id="Group_26">
                  <path
                    id="Vector_50"
                    d="M694.884 395.843C694.884 401.169 689.405 405.489 682.655 405.489C675.905 405.489 670.426 401.169 670.426 395.843C670.426 390.516 675.905 386.187 682.655 386.187C689.405 386.187 694.884 390.507 694.884 395.843Z"
                    fill="#F8FCFF"
                    stroke="#294456"
                    style="
                      fill: #f8fcff;
                      fill: color(display-p3 0.9725 0.9882 1);
                      fill-opacity: 1;
                      stroke: #294456;
                      stroke: color(display-p3 0.1608 0.2667 0.3373);
                      stroke-opacity: 1;
                    "
                    stroke-width="1.5924"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <g id="Group_27">
                    <path
                      id="Vector_51"
                      d="M682.73 391.194H687.117C689.104 391.194 690.092 392.078 690.092 393.753V397.311H682.73V391.194Z"
                      fill="#8FB4BA"
                      stroke="#294456"
                      style="
                        fill: #8fb4ba;
                        fill: color(display-p3 0.5608 0.7059 0.7294);
                        fill-opacity: 1;
                        stroke: #294456;
                        stroke: color(display-p3 0.1608 0.2667 0.3373);
                        stroke-opacity: 1;
                      "
                      stroke-width="1.5924"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      id="Vector_52"
                      d="M678.155 391.194H682.74V397.358L675.218 397.311V393.753C675.218 392.078 676.178 391.194 678.155 391.194Z"
                      fill="#8FB4BA"
                      stroke="#294456"
                      style="
                        fill: #8fb4ba;
                        fill: color(display-p3 0.5608 0.7059 0.7294);
                        fill-opacity: 1;
                        stroke: #294456;
                        stroke: color(display-p3 0.1608 0.2667 0.3373);
                        stroke-opacity: 1;
                      "
                      stroke-width="1.5924"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                </g>
                <g id="Group_28">
                  <path
                    id="Vector_53"
                    d="M450.345 395.843C450.345 401.169 444.875 405.489 438.116 405.489C431.366 405.489 425.887 401.169 425.887 395.843C425.887 390.516 431.366 386.187 438.116 386.187C444.875 386.187 450.345 390.507 450.345 395.843Z"
                    fill="#F8FCFF"
                    stroke="#294456"
                    style="
                      fill: #f8fcff;
                      fill: color(display-p3 0.9725 0.9882 1);
                      fill-opacity: 1;
                      stroke: #294456;
                      stroke: color(display-p3 0.1608 0.2667 0.3373);
                      stroke-opacity: 1;
                    "
                    stroke-width="1.5924"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <g id="Group_29">
                    <path
                      id="Vector_54"
                      d="M438.2 391.194H442.587C444.574 391.194 445.562 392.078 445.562 393.753V397.311H438.2V391.194Z"
                      fill="#8FB4BA"
                      stroke="#294456"
                      style="
                        fill: #8fb4ba;
                        fill: color(display-p3 0.5608 0.7059 0.7294);
                        fill-opacity: 1;
                        stroke: #294456;
                        stroke: color(display-p3 0.1608 0.2667 0.3373);
                        stroke-opacity: 1;
                      "
                      stroke-width="1.5924"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      id="Vector_55"
                      d="M433.616 391.194H438.2V397.358L430.679 397.311V393.753C430.679 392.078 431.639 391.194 433.616 391.194Z"
                      fill="#8FB4BA"
                      stroke="#294456"
                      style="
                        fill: #8fb4ba;
                        fill: color(display-p3 0.5608 0.7059 0.7294);
                        fill-opacity: 1;
                        stroke: #294456;
                        stroke: color(display-p3 0.1608 0.2667 0.3373);
                        stroke-opacity: 1;
                      "
                      stroke-width="1.5924"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                </g>
                <g id="Group_30">
                  <path
                    id="Vector_56"
                    d="M703.78 346.5C703.78 351.827 698.31 356.147 691.551 356.147C684.801 356.147 679.322 351.827 679.322 346.5C679.322 341.174 684.801 336.845 691.551 336.845C698.301 336.854 703.78 341.174 703.78 346.5Z"
                    fill="#F8FCFF"
                    stroke="#294456"
                    style="
                      fill: #f8fcff;
                      fill: color(display-p3 0.9725 0.9882 1);
                      fill-opacity: 1;
                      stroke: #294456;
                      stroke: color(display-p3 0.1608 0.2667 0.3373);
                      stroke-opacity: 1;
                    "
                    stroke-width="1.5924"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <g id="Group_31">
                    <path
                      id="Vector_57"
                      d="M691.626 341.861H696.013C698 341.861 698.988 342.736 698.988 344.411V347.969H691.626V341.861Z"
                      fill="#8FB4BA"
                      stroke="#294456"
                      style="
                        fill: #8fb4ba;
                        fill: color(display-p3 0.5608 0.7059 0.7294);
                        fill-opacity: 1;
                        stroke: #294456;
                        stroke: color(display-p3 0.1608 0.2667 0.3373);
                        stroke-opacity: 1;
                      "
                      stroke-width="1.5924"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      id="Vector_58"
                      d="M687.051 341.861H691.636V348.025L684.114 347.978V344.421C684.105 342.745 685.065 341.861 687.051 341.861Z"
                      fill="#8FB4BA"
                      stroke="#294456"
                      style="
                        fill: #8fb4ba;
                        fill: color(display-p3 0.5608 0.7059 0.7294);
                        fill-opacity: 1;
                        stroke: #294456;
                        stroke: color(display-p3 0.1608 0.2667 0.3373);
                        stroke-opacity: 1;
                      "
                      stroke-width="1.5924"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                </g>
                <g id="Group_32">
                  <path
                    id="Vector_59"
                    d="M441.458 346.5C441.458 351.827 435.988 356.147 429.229 356.147C422.479 356.147 417 351.827 417 346.5C417 341.174 422.47 336.845 429.229 336.845C435.979 336.854 441.458 341.174 441.458 346.5Z"
                    fill="#F8FCFF"
                    stroke="#294456"
                    style="
                      fill: #f8fcff;
                      fill: color(display-p3 0.9725 0.9882 1);
                      fill-opacity: 1;
                      stroke: #294456;
                      stroke: color(display-p3 0.1608 0.2667 0.3373);
                      stroke-opacity: 1;
                    "
                    stroke-width="1.5924"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <g id="Group_33">
                    <path
                      id="Vector_60"
                      d="M429.304 341.861H433.691C435.677 341.861 436.666 342.736 436.666 344.411V347.969H429.304V341.861Z"
                      fill="#8FB4BA"
                      stroke="#294456"
                      style="
                        fill: #8fb4ba;
                        fill: color(display-p3 0.5608 0.7059 0.7294);
                        fill-opacity: 1;
                        stroke: #294456;
                        stroke: color(display-p3 0.1608 0.2667 0.3373);
                        stroke-opacity: 1;
                      "
                      stroke-width="1.5924"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      id="Vector_61"
                      d="M424.729 341.861H429.314V348.025L421.792 347.978V344.421C421.782 342.745 422.743 341.861 424.729 341.861Z"
                      fill="#8FB4BA"
                      stroke="#294456"
                      style="
                        fill: #8fb4ba;
                        fill: color(display-p3 0.5608 0.7059 0.7294);
                        fill-opacity: 1;
                        stroke: #294456;
                        stroke: color(display-p3 0.1608 0.2667 0.3373);
                        stroke-opacity: 1;
                      "
                      stroke-width="1.5924"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                </g>
              </g>
            </g>
          </svg>
          <img
            class="universityImage"
            src="../../../assets/img/wuandStudents.png"
            alt="World a univeristy"
            (load)="onImageLoad()"
          />
        </div>
      </div>
      <div class="row secondRow">
        <!-- <div class="custom-shape-divider-top-1694247647">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
              class="shape-fill"
            ></path>
          </svg>
        </div> -->
        <div class="container-fluid">
          <div class="row">
            <!-- <div class="col-1">
              <img
              *ngIf="width > 1000"
                class="arrowLeft"
                src="../../../assets/img/Landing/arrowupleft.png"
                alt="arrowupleft"
              />
            </div> -->
            <div class="col-10 mx-auto">
              <div class="container">
                <div class="row processFlowRow">
                  <div class="col-md-12 col-sm-12 mx-auto alignCenter my-auto">
                    <h2>
                      Learn how
                      <span
                        ><img *ngIf="!isOsyncs" src="../../../assets/img/newBlueLogo.png" alt=""
                      />
                      <img *ngIf="isOsyncs" src="../../../assets/img/Osyncs logo.png" alt=""
                      /></span>
                      can provide wings to your Immigration business
                    </h2>
                    <p>
                      A CRM system tailored for educational institutions can
                      offer various features to streamline the admission
                      process, such as:
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-auto mx-auto">
                    <img
                      src="../../../assets/img/Aircraft.png"
                      alt=""
                      class="aircraft"
                    />
                  </div>
                </div>
                <div class="row iconsRow">
                  <div class="col-md-2 col-sm-6">
                    <div class="card shadow iconCard">
                      <div class="row">
                        <div class="col-auto mx-auto">
                          <img
                            src="../../../assets/img/applicationMngmt.png"
                            alt=""
                            class="iconImage"
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-auto mx-auto alignCenter">
                          <p>
                            Application <br />
                            Management
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 col-sm-6" style="margin-top: 40px">
                    <div class="card shadow iconCard">
                      <div class="row">
                        <div class="col-auto mx-auto">
                          <img
                            src="../../../assets/img/Communication.png"
                            alt=""
                            class="iconImage"
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-auto mx-auto alignCenter">
                          <p>
                            Communication <br />
                            Tools
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 col-sm-6" style="margin-top: 80px">
                    <div class="card shadow iconCard">
                      <div class="row">
                        <div class="col-auto mx-auto">
                          <img
                            src="../../../assets/img/Workflow.png"
                            alt=""
                            class="iconImage"
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-auto mx-auto alignCenter">
                          <p>
                            Workflow <br />
                            Automation
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 col-sm-6" style="margin-top: 80px">
                    <div class="card shadow iconCard">
                      <div class="row">
                        <div class="col-auto mx-auto">
                          <img
                            src="../../../assets/img/document.png"
                            alt=""
                            class="iconImage"
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-auto mx-auto alignCenter">
                          <p>
                            Document <br />
                            Manager
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 col-sm-4" style="margin-top: 40px">
                    <div class="card shadow iconCard">
                      <div class="row">
                        <div class="col-auto mx-auto">
                          <img
                            src="../../../assets/img/analytics.png"
                            alt=""
                            class="iconImage"
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-auto mx-auto alignCenter">
                          <p>
                            Analytics and <br />
                            Reporting
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 col-sm-4">
                    <div class="card shadow iconCard">
                      <div class="row">
                        <div class="col-auto mx-auto">
                          <img
                            src="../../../assets/img/CRM.png"
                            alt=""
                            class="iconImage"
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-auto mx-auto alignCenter">
                          <p>
                            CRM <br />
                            Integration
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="col-1">
              <img
              *ngIf="width > 1000"
                class="arrowLeft"
                src="../../../assets/img/Landing/arrows_more_up.png"
                alt="arrowupleft"
              />
            </div> -->
          </div>
        </div>
      </div>
      <div class="row thirdRow">
        <img src="../../../assets/img/Tower.png" alt="" class="tower2" />
        
        <div class="col-md-1"></div>
        <div
        class="card col-md-6 col-xsm-12 mx-auto my-auto admissionSupportCard"
        >
        <!-- <img src="../../../assets/img/aPassport.png" alt="" class="tower2" /> -->

          <h2>
            Empowering Students with the Best College and University Choices in
            Australia, Canada, and New Zealand
          </h2>
          <p>
            At Aplifast, we understand that choosing the right college or
            university is a crucial decision for students. We believe that every
            student deserves the opportunity to explore the best educational
            institutions around the world. That's why we have developed a
            comprehensive platform that provides a curated list of the top
            colleges and universities in Australia, Canada, and New Zealand.
          </p>
        </div>
        <div class="col-md-4 col-xsm-12 mx-auto alignCenter">
          <img
            class="worldEarthGirl"
            src="../../../assets/img/gilr-earth.png"
            alt=""
          />
        </div>
      </div>
      <div class="row thirdRow">
        <img src="../../../assets/img/Tower2.png" alt="" class="tower" />

        <div class="col-md-1"></div>
        <div
          class="card col-md-6 col-xsm-12 mx-auto my-auto admissionSupportCard"
        >

          <h2>Admission & Visa Support</h2>
          <p>
            At Admission Visa & Support, we understand that the visa process can
            be complex and overwhelming for students who wish to study abroad.
            We believe that every student deserves professional guidance and
            support throughout the visa application process to ensure a smooth
            and successful transition to their chosen destination. Our team of
            experienced professionals is dedicated to assisting students in
            obtaining the necessary visas and providing ongoing support
            throughout their educational journey.
          </p>
        </div>
        <div class="col-md-4 col-xsm-12 mx-auto alignCenter">
          <img
            class="worldEarthGirl"
            src="../../../assets/img/admission.png"
            alt=""
          />
        </div>
      </div>
      <div class="row thirdRow">
        <img src="../../../assets/img/Dome 2.png" alt="" class="tower2" />
        <div class="col-md-1"></div>
        <div
          class="card col-md-6 col-xsm-12 mx-auto my-auto admissionSupportCard"
        >

          <h2>Upload Documents & Live Chat</h2>
          <p>
            we are dedicated to providing you with a seamless and exceptional
            customer support journey. Our End-to-End 360° Customer Support aims
            to address your needs comprehensively, ensuring that you receive the
            assistance you require and have a positive experience with our
            products and services. We are here to help, so please don't hesitate
            to reach out to us for any assistance you may need.
          </p>
        </div>
        <div class="col-md-4 col-xsm-12 mx-auto alignCenter">
          <img
            class="worldEarthGirl"
            src="../../../assets/img/liveChat.png"
            alt=""
          />
        </div>
      </div>
      <div class="row thirdRow">
        <img src="../../../assets/img/Tower2.png" alt="" class="tower4" />

        <div class="col-md-1"></div>
        <div
          class="card col-md-6 col-xsm-12 mx-auto my-auto admissionSupportCard"
        >

          <h2>Real Time Application Status</h2>
          <p>
            Welcome to our real-time application status feature! We understand
            the importance of staying updated on the progress of your
            application. With our real-time tracking system, you can easily
            monitor the status of your application at any time.
          </p>
        </div>
        <!-- <img src="../../../assets/img/Cone2.png" alt="" class="tower" /> -->

        <div class="col-md-4 col-xsm-12 mx-auto alignCenter">
          <img
            class="worldEarthGirl"
            src="../../../assets/img/applicationStatus.png"
            alt=""
          />
        </div>
      </div>
      <div class="row thirdRow">
        <img src="../../../assets/img/Torus Half Sharp.png" alt="" class="tower3" />

        <div class="col-md-1"></div>
        <div
          class="card col-md-6 col-xsm-12 mx-auto my-auto admissionSupportCard"
        >

          <h2>End-to-End 360° Customer Support</h2>
          <p>
            we are dedicated to providing you with a seamless and exceptional
            customer support journey. Our End-to-End 360° Customer Support aims
            to address your needs comprehensively, ensuring that you receive the
            assistance you require and have a positive experience with our
            products and services. We are here to help, so please don't hesitate
            to reach out to us for any assistance you may need.
          </p>
        </div>
        <div class="col-md-4 col-xsm-12 mx-auto alignCenter">
          <img
            class="worldEarthGirl"
            src="../../../assets/img/customerSupport.png"
            alt=""
          />
        </div>
      </div>
      <div class="row footer">
        <div class="container">
          <div class="row">
            <div style="text-align: center" class="col-lg-4 mx-auto">
              <img *ngIf="!isOsyncs" src="../../../assets/img/newBlueLogo.png" alt="" />
              <img *ngIf="isOsyncs" src="../../../assets/img/Osyncs logo.png" alt="" />
              <h2 class="footerHead">Let's Work Together</h2>
              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Professional email address"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                />
                <div class="input-group-append">
                  <span id="basic-addon2"
                    ><button class="btn btn-primary subBtn">
                      Submit
                    </button></span
                  >
                </div>
              </div>
              <ul class="list-inline">
                <li class="list-inline-item" (click)="selectPolicy('privacy')">
                  <a
                    class="text-xs-center policyLink"
                    data-toggle="modal"
                    data-target="#bd-example-modal-lg"
                    >Privacy Policy</a
                  >
                </li>
                <li class="list-inline-item separator">|</li>
                <li class="list-inline-item" (click)="selectPolicy('refund')">
                  <a
                    class="text-xs-center policyLink"
                    data-toggle="modal"
                    data-target="#bd-example-modal-lg"
                    >Refund Policy</a
                  >
                </li>
                <li class="list-inline-item separator">|</li>
                <li class="list-inline-item" (click)="selectPolicy('tandc')">
                  <a
                    class="text-xs-center policyLink"
                    data-toggle="modal"
                    data-target="#bd-example-modal-lg"
                    >T & C</a
                  >
                </li>
              </ul>
              <ul class="list-inline">
                <li class="list-inline-item">
                  <a
                    class="text-xs-center policyLink"
                    href="https://api.whatsapp.com/send?phone=919464526726"
                    ><img src="../../../assets/img/whatsapp-square.png" alt=""
                  /></a>
                </li>
                <li class="list-inline-item separator">|</li>
                <li class="list-inline-item">
                  <a
                    class="text-xs-center policyLink"
                    href="https://www.instagram.com/themiigratorsbathinda/"
                    ><img src="../../../assets/img/instagram.png" alt=""
                  /></a>
                </li>
                <li class="list-inline-item separator">|</li>
                <li class="list-inline-item">
                  <a
                    class="text-xs-center policyLink"
                    href="https://www.linkedin.com/in/the-miigrators-overseas-education-consultants-0790a520b/"
                    ><img src="../../../assets/img/linkedin.png" alt=""
                  /></a>
                </li>
                <li class="list-inline-item separator">|</li>
                <li class="list-inline-item">
                  <a
                    class="text-xs-center policyLink"
                    href="https://www.facebook.com/themiigratorsbathinda/"
                    ><img src="../../../assets/img/facebook-square.png" alt=""
                  /></a>
                </li>
              </ul>
            </div>
          </div>
          <hr style="color: white" />
          <div class="row">
            <div class="col-lg-6">
              <p class="footerP">Copyright @ 2022 All Rights Reserved</p>
            </div>
            <!-- <div class="col-6"></div> -->
            <div class="col-lg-6">
              <p class="footerPR">Developed by Osyncs</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </body>
</html>
<div
class="modal fade"
id="exampleModalCenter"
tabindex="-1"
role="dialog"
aria-labelledby="exampleModalCenterTitle"
aria-hidden="true"
>
<div class="modal-dialog modal-dialog-centered loginModalDialog" role="document">
  <div class="modal-content">
    <div class="modal-header loginModalHeader">
      <h5 class="modal-title" id="exampleModalCenterTitle">Login</h5>
      <button
        id="closeButton"
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form
        #EntryForm="ngForm"
        autocomplete="off"
        (ngSubmit)="submitform(EntryForm)"
      >
        <div class="inputBox">
          <i class="fas fa-envelope prefix grey-text"></i>
          <label for="defaultForm-email">Your email</label>
          <input
            type="email"
            name="email"
            #emailRef="ngModel"
            id="email"
            class="form-control"
            ngModel
          />
        </div>

        <div class="inputBox">
          <i class="fas fa-lock prefix grey-text"></i>
          <label for="defaultForm-pass">Your password</label>
          <input
            type="password"
            name="password"
            #passwordRef="ngModel"
            id="defaultForm-pass"
            class="form-control"
            ngModel
          />
        </div>
        <div class="row mx-auto">
          <button
            [disabled]="!viewLoader"
            type="submit"
            id="loginButton"
            class="btn btn-primary mx-auto loginButton"
          >
            Login
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
</div>
